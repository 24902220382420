/**
 * Component QualityView comentarios en español y 3 mas
 * @author: Luis Avello
 * @version 1.2.0
 * @updated 2023-09-26
 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ListIcon from "@mui/icons-material/List";
import AddIcon from "@mui/icons-material/Add";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import InsightsIcon from "@mui/icons-material/Insights";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Stack from "@mui/material/Stack";

import QualityLineItem from "./quality_line_item";
import PlantSelect from "../dashboard/plant_select";
import TreePms from "../tree/tree_pms";
import useInterval from "../hooks/interval";
import DataPlants from "./data_plants";
//import FormList from "./form_list";
import FormList from "../views/quality/form_list";
import FormLoginDialog from "../views/quality/form_login_dialog";
const urlLogout = "/api/react/v1/quality/logout";
const urlCurrentLogin = "/api/react/v1/quality/current_logged";

import {
  responseLog,
  responseInfo,
  responseError,
} from "../utils/response_console";

const QualityView = (props) => {
  const [plant, setPlant] = useState({ id: "", name: "", active_lines: [] });
  const [plants, setPlants] = useState([]);
  const [update, setUpdate] = useState(0);
  const [showDash, setShowDash] = useState(true);
  const timeUpdate = 5 * 60 * 1000;
  const urlPlants = "/api/react/v1/dashboard/plants";
  const test = false;
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
  const [inspectorIsLogged, setInspectorIsLogged] = React.useState(false);
  const [nameInspector, setNameInspector] = React.useState("");

  const handleChangePlant = (event) => {
    let [first] = plants.filter((item) => item.id == event.target.value);
    setPlant(first);
  };

  const changePlant = (id) => {
    let [first] = plants.filter((item) => item.id == id);
    setPlant(first);
  };

  const handleChangeView = (event) => {
    setShowDash((current) => !current);
  };

  const handleLogin = (event) => {
    console.log("Handle Login");
    if (inspectorIsLogged) logoutInspector();
    else setOpenLoginDialog(true);
  };

  const logoutInspector = () => {
    axios
      .get(urlLogout, {
        params: {},
      })
      .then((response) => {
        responseInfo(response, true);
        setInspectorIsLogged(false);
        setNameInspector("");
      })
      .catch((error) => {
        responseError(error);
      });
  };

  const handleTickets = () => {
    console.log("Link to Tickets");
    window.location.href = "/quality/tickets";
  };

  const setOpenDialogLogin = () => {};

  useInterval(() => {
    setUpdate((prevUpdate) => prevUpdate + 1);
    console.log("Update  " + update);
  }, timeUpdate);

  useEffect(() => {
    if (!test) {
      axios
        .get(urlPlants)
        .then((response) => {
          responseInfo(response, true);
          setPlants(response.data);
          let [first] = response.data;
          setPlant(first);
        })
        .catch((error) => {
          responseError(error);
        });

      axios
        .get(urlCurrentLogin, {
          params: {},
        })
        .then((response) => {
          responseInfo(response, true);
          setInspectorIsLogged(response.data.logged);
          setNameInspector(response.data.name);
        })
        .catch((error) => {
          responseError(error);
        });
    } else {
      console.log("-> QualityView Test ON !");
      setPlants(DataPlants);
      let [first] = DataPlants;
      setPlant(first);
    }
  }, [update]);

  return (
    <Container maxWidth="false">
      <Grid container spacing={2}>
        <Grid xs={12} sx={{ display: { xs: "none", sm: "none" } }}>
          <PlantSelect
            label="Planta"
            list={plants}
            selected={plant.id}
            handleChange={handleChangePlant}
          />
        </Grid>
        <Grid sm={4} md={2} sx={{ display: { xs: "none", sm: "none" } }}>
          <TreePms plants={plants} changePlant={changePlant} />
        </Grid>

        <Grid container spacing={2} sm={8} md={10}>
          <Grid sm={12} md={12}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              spacing={2}
              sx={{ pt: 1 }}
            >
              {showDash ? (
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<FormatListNumberedIcon />}
                  color="primary"
                  onClick={handleChangeView}
                >
                  Consulta Formularios
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<FormatListNumberedIcon />}
                  color="primary"
                  onClick={handleChangeView}
                >
                  Formularios por Linea
                </Button>
              )}

              <Button
                size="small"
                variant="outlined"
                startIcon={<BuildCircleIcon />}
                color="primary"
                href="/quality/tickets"
              >
                Tickets
              </Button>

              <Button
                size="small"
                variant="outlined"
                startIcon={<InsightsIcon />}
                color="primary"
                href="/report/quality"
              >
                Reportes
              </Button>

              {false ? (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleLogin}
                >
                  {inspectorIsLogged
                    ? `Logout ${nameInspector}`
                    : "Login Inspector"}
                </Button>
              ) : null}
            </Stack>
          </Grid>
          {showDash ? (
            plant.active_lines.map((line) => (
              <Grid key={line.id} xs={12} md={3}>
                <QualityLineItem line={line} update={update} test={test} />
              </Grid>
            ))
          ) : (
            <Grid xs={12} sm={12} md={12}>
              <FormList />
            </Grid>
          )}
        </Grid>
      </Grid>
      <FormLoginDialog
        openDialog={openLoginDialog}
        handleOpenDialog={(newState) => setOpenLoginDialog(newState)}
        handleLoginResult={(result) => setInspectorIsLogged(result)}
        handleNameInspector={(name) => setNameInspector(name)}
      />
    </Container>
  );
};

export default QualityView;
